<template>
  <div :class="$style.root">
    <!-- 予約注文の場合 -->
    <AtomsConsumerButton
      v-if="sku.is_reservation && !isOutOfStock"
      variant="primary"
      :class="className"
      :rounded="rounded"
      @click="addCart"
    >
      予約注文する
    </AtomsConsumerButton>

    <!-- 通常注文の場合 -->
    <AtomsConsumerButton
      v-else-if="!isOutOfStock"
      variant="primary"
      :class="className"
      :disabled="isCartNotAddable"
      :rounded="rounded"
      @click="addCart"
    >
      {{ isCartNotAddable ? "発売開始前" : "カートに追加" }}
    </AtomsConsumerButton>

    <!-- 再入荷通知 -->
    <AtomsConsumerButton
      v-else-if="isRestockNotice"
      variant="primary"
      :class="className"
      :rounded="rounded"
      @click="changeRestockNoticeModalState"
    >
      再入荷のお知らせ登録へ
    </AtomsConsumerButton>

    <p v-if="!isOutOfStock && isEmbroidery && !isEmbroideryConfirm"
      >※通常商品と刺繍商品は同時購入不可です。追加する場合は刺繍設定した商品をカートから削除してください</p
    >
    <OrderPreOrderAttention v-model:visible="isPreOrderModalVisible" @confirm="beforeVisibleCheckout" />
    <OrderDuvetCollectionModal
      v-model:visible="isDuvetCollectionModalVisible"
      @cancel="isDuvetCollectionModalVisible = false"
      @done="beforeVisibleCheckout"
    />
  </div>
</template>

<script setup lang="ts">
import type { CartItem, CartGift, CartEmbroidery } from "@tential/ec-gql-schema/models/order"
import { embroideryExclusiveProductsInCart, isEmbroideryProductsInCart } from "@tential/ec-gql-schema/utils/embroidery"
import { PagePartsProductType } from "~/types/product"
import { SkuFragmentFragment } from "~/types/type.generated"
import { useToast, useRestockNoticeModal } from "~/state"
import { useCart } from "~/composables/useCart"
import { useDuvetCollection } from "~/composables/useDuvetCollection"
import { useStore } from "~/stores"
import { EventHandler } from "~/types/events"
import { isSkuOutOfStock } from "~/utils/sku"
import { checkGiftItemLimit } from "~/utils/functions/validate"

type Emits = {
  (e: "select-size-validation"): void
  (e: "after-add-cart"): void
}

const props = withDefaults(
  defineProps<{
    product: PagePartsProductType
    sku: SkuFragmentFragment
    selectedSize: string
    /** カートに追加可能かどうか */
    isCartNotAddable: boolean
    quantity?: number
    gift?: CartGift
    isConfirm?: boolean
    isEGift?: boolean
    canNotSetEGift?: boolean
    isEmbroideryConfirm?: boolean
    embroidery?: CartEmbroidery
    rounded?: boolean

    /**
     * Google Tag Managerのイベントトラッキング用のクラス gtm_add_cart_btn_ に続く文字列を指定する
     * 例: "gtm_add_cart_btn_{{ product.slug }}"
     */
    className?: string
  }>(),
  {
    selectedSize: "",
    className: "",
    quantity: 1,
    gift: undefined,
    isConfirm: false,
    isEGift: false,
    canNotSetEGift: false,
    isEmbroideryConfirm: false,
    embroidery: undefined,
    rounded: false,
  },
)

const store = useStore()
const toast = useToast()
const cart = useCart()
const { showDuvetCollection, getDuvetCollectionType } = useDuvetCollection()
const restockNoticeModal = useRestockNoticeModal()
const emit = defineEmits<Emits>()

const isOutOfStock = computed(() => {
  return isSkuOutOfStock(props.sku)
})

const isOutOfStockForReservation = computed(() => {
  const net_stock_quantity = props.sku.net_stock_quantity || 0
  const net_stock_quantity_limit = props.sku.net_stock_quantity_limit || 0
  const cartItem = cart.cartLine.value.find((item) => item.sku.document_id === props.sku.document_id)
  const cartItemQuantity = cartItem?.quantity || 0
  const totalQuantity = props.quantity + cartItemQuantity
  return props.sku.is_reservation && net_stock_quantity - totalQuantity < net_stock_quantity_limit - 1
})

const isEmbroidery = computed(() => isEmbroideryProductsInCart(store.cartList))

const embroideryExclusive = computed(() => embroideryExclusiveProductsInCart(store.cartList))

const isRestockNotice = computed(() => props.sku.is_restock_notice === true)

const isPreOrderModalVisible = ref<boolean>(false)
const isDuvetCollectionModalVisible = ref<boolean>(false)

const addCart = async (): Promise<void> => {
  emit("select-size-validation")
  if (!props.selectedSize && !props.isEGift) return

  // 初期描画時、cart.cartLineの情報を持ってないので計算させ取得する
  if (cart.cartLine.value.length === 0) {
    await cart.calcCart({ cartList: store.cartList })
  }

  // 選択した商品が在庫切れの場合はエラーを表示
  if (isOutOfStock.value) {
    toast?.showErrorToasted("選択いただいた商品は在庫切れです")
    return
  }

  // ギフト商品の購入上限を超えている場合はエラーを表示
  if (checkGiftItemLimit(cart.cartLine.value, props.product.cart_only_gift, props.quantity)) {
    toast?.showErrorToasted("単品でのギフト資材は、1回のご注文で最大3点までしかカートに追加できません")
    return
  }

  if (props.quantity <= 0) return
  if (!props.product.document_id || !props.sku) {
    toast?.showErrorToasted("エラーにより、この商品はカートに追加できませんでした")
    return
  }

  // TODO: canNotSetEGiftの名前を変更する
  if (props.isEGift && (props.canNotSetEGift || props.product.is_duvet_collection)) {
    toast?.showErrorToasted("eギフトの設定ができない商品です")
    return
  }

  if (isEmbroidery.value && !props.isEmbroideryConfirm) {
    toast?.showErrorToasted("刺繍商品と通常商品は同時にカートに追加することができません")
    return
  }

  if (!isEmbroidery.value && store.cartList.length > 0 && props.isEmbroideryConfirm) {
    toast?.showErrorToasted("刺繍商品と通常商品は同時にカートに追加することができません")
    return
  }

  if (embroideryExclusive.value && props.isEmbroideryConfirm) {
    toast?.showErrorToasted("刺繍限定商品は、通常の刺繍商品と同時にカートに追加することができません")
    return
  }

  // 購入点数制限がある商品の点数チェック
  if (props.product.order_quantity_limit > 0 && props.product.order_quantity_limit_type) {
    // カート内に同一商品が何点あるか
    const sameProductQuantity = cart.cartLine.value
      .filter((cartItem) => cartItem.product.document_id === props.product.document_id)
      .reduce((acc, cartItem) => acc + cartItem.count, 0)
    if (props.product.order_quantity_limit_type === "order") {
      // 数量が購入点数制限より大きい場合はエラー
      if (sameProductQuantity > props.product.order_quantity_limit) {
        toast?.showErrorToasted(
          `この商品は、1回のご注文につき、${props.product.order_quantity_limit}個まで購入できます`,
        )
        return
      }
    } else if (props.product.order_quantity_limit_type === "not_gift_sku") {
      // ギフト商品＆ラッピング済み商品を除いた商品の数量
      const notGiftSkuQuantity = cart.cartLine.value.reduce((total, cartItem) => {
        if (!cartItem.product.is_gift && !cartItem.product.is_wrapping) return total + cartItem.count
        return total
      }, 0)
      // 数量がカート個数*購入点数制限より大きい場合はエラー
      if (sameProductQuantity > props.product.order_quantity_limit * notGiftSkuQuantity) {
        toast?.showErrorToasted(
          `この商品は、商品1点（ラッピング袋・カードを除く）につき、${props.product.order_quantity_limit}個まで購入できます`,
        )
        return
      }
    }
  }

  if (isOutOfStockForReservation.value) {
    const net_stock_quantity = props.sku.net_stock_quantity || 0
    const net_stock_quantity_limit = props.sku.net_stock_quantity_limit || 0
    const availableQuantity =
      (net_stock_quantity ?? 0) - (net_stock_quantity_limit ?? 0) + 1 < 0
        ? 0
        : (net_stock_quantity ?? 0) - (net_stock_quantity_limit ?? 0) + 1
    toast?.showErrorToasted(`予約購入できる個数は${availableQuantity}個までです`)
    return
  }

  if (props.sku.is_reservation) {
    isPreOrderModalVisible.value = true
  } else if (showDuvetCollection() && props.product.is_duvet_collection && getDuvetCollectionType() === "none") {
    isDuvetCollectionModalVisible.value = true
  } else {
    beforeVisibleCheckout()
  }
}
defineExpose({
  addCart,
})

const beforeVisibleCheckout = async () => {
  await addCartItems()
  cart.openAddCartModal([props.sku.document_id])
  isPreOrderModalVisible.value = false
  isDuvetCollectionModalVisible.value = false
  emit("after-add-cart")
}

const addCartItems = async () => {
  const cartItems: CartItem[] = []
  for (let i = 1; i <= props.quantity; i++) {
    cartItems.push({
      product_id: props.product.document_id,
      product_name: props.product.name,
      sku_id: props.sku.document_id,
      site_id: props.sku.site_id || undefined,
      sku_name: props.sku.name,
      is_reservation: props.sku.is_reservation || false,
      material: props.product.material || undefined,
      net_stock_quantity: props.sku.net_stock_quantity || 0,
      net_stock_quantity_limit: props.sku.net_stock_quantity_limit || 0,
      gift: (props.gift?.sku_id || props.gift?.card_sku_id) && props.isConfirm ? props.gift : undefined,
      is_bundled_wrapping: props.product.is_gift || props.product.cart_only_gift,
      is_wrapping_product: props.product.is_wrapping,
      wrapping_sku_id: props.product.wrapping_sku_id,
      embroidery: props.isEmbroideryConfirm && props.embroidery?.text !== "" ? props.embroidery : undefined,
      is_duvet_collection: props.product.is_duvet_collection,
    })
  }
  await cart.addCartMultiItem({ cartItems, skuIds: [props.sku.document_id] })
}

const changeRestockNoticeModalState: EventHandler = () => {
  emit("select-size-validation")
  if (!props.selectedSize && !props.isEGift) return
  restockNoticeModal?.entryModalHandler()
}
</script>

<style scoped module lang="scss">
.root {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    font-size: $font-size-10;
    @include md {
      font-size: $font-size-12;
    }
  }

  button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
